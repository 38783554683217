import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import Sections from '../components/Sections';
import { ContentMain, device } from '../components/commonStyled';
import Form from '../components/Form';
import { CompatibleImg } from '../components/Img';
import { WP_FORM_API_FEEDBACK } from '../constants';

const SocialContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
`;

const SocialItem = styled.a`
    display: block;
    height: 74px;
    width: 74px;
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    @media ${device.mobileMedium} {
        height: 46px;
        max-width: 46px;
    }

    &:not(:last-child) {
        margin-right: 16px;

        @media ${device.mobileMedium} {
            margin-right: 8px;
        }
    }

    &:hover {
        transform: scale(1.05);
    }
`;

const EmailContainer = styled.a`
    display: flex;
    align-items: center;
    height: 18px;
    color: #000000;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 24px;
`;

export default ({ data, location }) => {
    const {
        title,
        seo,
        common_acf: { blocks },
        contacts_acf: {
            email,
            social_media: {
                whatsapp_image,
                whatsapp_link,
                viber_image,
                viber_link,
                telegram_image,
                telegram_link,
                skype_image,
                skype_link,
                insta_image,
                insta_link,
                linkedIn_image,
                linkedIn_link,
            },
        },
    } = data?.wpPage;

    const socialMedia = [
        {
            image: whatsapp_image,
            link: whatsapp_link,
        },
        {
            image: viber_image,
            link: viber_link,
        },
        {
            image: telegram_image,
            link: telegram_link,
        },
        {
            image: skype_image,
            link: skype_link,
        },
        {
            image: insta_image,
            link: insta_link,
        },
        {
            image: linkedIn_image,
            link: linkedIn_link,
        },
    ];

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <SocialContainer>
                    {socialMedia.map(({ image, link }) => (
                        <SocialItem
                            href={link}
                            target="_blank"
                            rel="nofollow"
                            key={link}
                        >
                            <CompatibleImg image={image?.localFile} />
                        </SocialItem>
                    ))}
                </SocialContainer>
                <EmailContainer href={`mailto:${email}`}>
                    {email}
                </EmailContainer>
            </ContentMain>
            <Form
                apiUrl={WP_FORM_API_FEEDBACK}
                placeholders={{ text: 'Опишите ваш вопрос *' }}
                buttonText="Отправить"
            />
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: {
                        whiteStyle: true,
                        noButton: true,
                        order: 3,
                    },
                }}
            />
        </PageLayout>
    );
};

export const pageQuery = graphql`
    query ContactsPage {
        wpPage(common_acf: { id: { eq: "contacts" } }) {
            title
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
            contacts_acf {
                email
                social_media: socialMedia {
                    whatsapp_image: whatsappImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                    whatsapp_link: whatsappLink
                    viber_image: viberImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                    viber_link: viberLink
                    telegram_image: telegramImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                    telegram_link: telegramLink
                    skype_image: skypeImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                    skype_link: skypeLink
                    insta_image: instaImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                    insta_link: instaLink
                    linkedIn_image: linkedinImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 100)
                            }
                        }
                    }
                    linkedIn_link: linkedinLink
                }
            }
        }
    }
`;
